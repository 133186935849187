import Typography from "typography"

const typography = new Typography()

// Hot reload typography in development.
if (process.env.NODE_ENV !== "production") {
  typography.injectStyles()
}

export default typography
export const { rhythm } = typography
export const { scale } = typography
